import React, { useState,useContext } from "react";
import UsuariosServiceInstance from "../../Servicios/UsuariosService";
import { LoginContext } from "../../Contexto/LoginContext";
import { TextField, Button, Box, Typography, InputAdornment } from '@mui/material';
import { AccountCircle, Lock} from '@mui/icons-material';
import LoginImage from '../../assets/logo.png'

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginMessage, setLoginMessage] = useState("");
  
    const { login } = useContext(LoginContext);

    const logins = async (usuarioLogin) => {
        try {
            const response = await UsuariosServiceInstance.login(usuarioLogin);
            login(response);
            console.log(response)
            const role = response.role
            
            if (role === "admin") {
                localStorage.setItem('userRole', role);
                window.location.href = '/crearingrediente' 
            }if(role==="cliente"){
                 localStorage.setItem('userRole', role);
                window.location.href = '/CrearPedido' 
        }} catch (error) {
            console.error("Error durante el inicio de sesión:", error);
            setLoginMessage("Ocurrió un error, por favor intenta nuevamente");
        }
    };

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center',
            textAlign:'center',
            margin:'auto',
            padding:'20px',
            backgroundColor:'#F2F3F4',
            borderRadius:'10px',
            maxWidth:'400px',
            boxShadow:3
        }}>
            <img src={LoginImage} alt="logo-patagonia-greens"
            style={{
                width:'200px',
                marginBottom:'20px'
            }} />
           
            <TextField id="outlined-basic" label="Nombre" variant="outlined"
             sx={{
              marginBottom:'16px',
              width:'100%'
               }}
            value={username}
            onChange={e => setUsername(e.target.value)}
            InputProps = {{
                startAdornment:(
                  <InputAdornment position="start">
                    <AccountCircle />
                   </InputAdornment>
                ),

            }}
             />
             
           <TextField id='outlined-basic' label='Contraseña'
           variant="outlined"
           sx={{
               marginBottom:'16px',
               width:'100%'
           }}
           type="password"
           value={password}
           onChange={e => setPassword(e.target.value)}
           InputProps = {{
            startAdornment: (
                <InputAdornment position="start">
                    <Lock />
                </InputAdornment>
            )
           }} />
           
           <Button variant="contained"
           sx={{
            marginBottom:'20px'
           }}
           onClick={() => logins({ username, password })}
           >Ingresar</Button>
           <Typography>{loginMessage}</Typography>
        </Box>
    );
}

export default Login;