import { API_URLS } from '../constantes/configs';

class UsuariosService {
    async getUser() {
        try {
            const response = await fetch(API_URLS.users);
            if (!response.ok) {
                throw new Error("Error para obtener los usuarios");
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    async crearUsuario(user) {
        try {
            const response = await fetch(API_URLS.user, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(user),
            });
    
            // Verifica si el estado es 201 (usuario creado)
            if (response.status === 201) {
                const data = await response.json(); // Obtener los datos de la respuesta
                return data; // Retorna los datos en caso de éxito
            } else {
                const errorData = await response.json(); // Obtener el error en caso de fallo
                throw new Error(errorData.message || "Error inesperado al crear usuario.");
            }
        } catch (error) {
            console.error("Error en crearUsuario:", error);
            throw error; // Relanza el error para ser manejado en otro lado
        }
    }
    

    async login(user) {
        try {
            const response = await fetch(API_URLS.login, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(user),
            });
            if (!response.ok) {
                throw new Error("Error para encontrar usuario");
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    async verificarUsuarioOEmail(username, email) {
        try {
            const response = await fetch(`${API_URLS.verificarUsuario}?username=${username}&email=${email}`);
            if (!response.ok) {
                throw new Error("Error en la verificación de usuario o email");
            }
            return response; // Regresa el estado de la respuesta
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

const UsuariosServiceInstance = new UsuariosService();
export default UsuariosServiceInstance;
