import React, { useState } from "react";
import UsuariosServiceInstance from "../../Servicios/UsuariosService";
import { Box, TextField, Button, InputAdornment, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";  // Importamos useNavigate
import logoRegister from '../../assets/logo.png';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

function Registro() {
    const [role, setRole] = useState('');
    const [usuario, setUsuario] = useState('');
    const [apellido, setApellido] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [numero, setNumero] = useState('');
    const [confirmarContraseña, setConfirmarContraseña] = useState('');

    // Estado para manejar el mensaje de éxito o error
    const [mensaje, setMensaje] = useState('');
    const [error, setError] = useState('');

    // Redireccionar al usuario a la página de inicio si el registro es exitoso
    const navigate = useNavigate(); 

    // Función para verificar si el username o el email ya están en uso
    const verificarDisponibilidad = async () => {
        try {
            const response = await UsuariosServiceInstance.verificarUsuarioOEmail(usuario, email);
            if (response.status === 200) {
                // Si no existe el username o email
                return true;
            } else {
                setError("El nombre de usuario o correo ya están en uso");
                return false;
            }
        } catch (error) {
            console.error("Error al verificar disponibilidad", error);
            setError("Error al verificar el nombre de usuario o correo");
            return false;
        }
    };

    // Función para enviar el formulario y crear un usuario
    const enviar = async (user) => {
        setMensaje(''); // Limpiar mensajes previos
        setError(''); // Limpiar errores previos
    
        if (await verificarDisponibilidad()) { // Verificamos antes de enviar
            try {
                console.log("Datos que se enviarán al backend:", user); // Log para ver los datos
                const data = await UsuariosServiceInstance.crearUsuario(user);
    
                // Si llegamos aquí, el usuario fue creado con éxito
                setMensaje("Usuario creado correctamente. Redirigiendo...");
    
                setTimeout(() => {
                    navigate("/home");  // Redirigir al home después de 2 segundos
                }, 2000);
            } catch (error) {
                console.error("Error al registrar usuario:", error);
                setError("Error al registrar usuario"); // Mostrar mensaje de error
            }
        }
    };    

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            margin: 'auto',
            padding: '20px',
            backgroundColor: '#F2F3F4',
            borderRadius: '10px',
            maxWidth: '400px',
            boxShadow: 3
        }}>
            <img src={logoRegister} alt="logo-register"
                style={{
                    width: '200px',
                    marginBottom: '20px'
                }} />

            <TextField
                id="outlined-role"
                label="Role (cliente o admin)"
                variant="outlined"
                type="text"
                sx={{
                    width: '100%',
                    marginBottom: '16px'
                }}
                value={role}
                onChange={e => setRole(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PeopleOutlineOutlinedIcon />
                        </InputAdornment>
                    )
                }}
            />

            <TextField
                id="outlined-nombre"
                label='Nombre'
                variant="outlined"
                sx={{
                    width: '100%',
                    marginBottom: '16px'
                }}
                value={usuario}
                onChange={e => setUsuario(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <PersonOutlinedIcon />
                        </InputAdornment>
                    )
                }} />

            <TextField
                id="outlined-apellido"
                label='Apellido'
                variant="outlined"
                sx={{
                    width: '100%',
                    marginBottom: '16px'
                }}
                value={apellido}
                onChange={e => setApellido(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PersonOutlinedIcon />
                        </InputAdornment>
                    )
                }} />

            <TextField
                id="outlined-email"
                label="Email"
                variant="outlined"
                sx={{
                    width: '100%',
                    marginBottom: '16px'
                }}
                value={email}
                onChange={e => setEmail(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <MarkEmailReadOutlinedIcon />
                        </InputAdornment>
                    )
                }}
            />

            <TextField
                id="outlined-phone"
                label="Tu celular"
                variant="outlined"
                sx={{
                    width: '100%',
                    marginBottom: '16px'
                }}
                value={numero}
                onChange={e => setNumero(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment>
                            <PhoneAndroidOutlinedIcon />
                        </InputAdornment>
                    )
                }} />

            <TextField
                id="outlined-password"
                label="Contraseña"
                variant="outlined"
                sx={{
                    width: '100%',
                    marginBottom: '16px'
                }}
                value={password}
                onChange={e => setPassword(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment>
                            <HttpsOutlinedIcon />
                        </InputAdornment>
                    )
                }} />

            <TextField
                id="outlined-confirm-password"
                label="Confirmar contraseña"
                variant="outlined"
                sx={{
                    width: '100%',
                    marginBottom: '16px'
                }}
                value={confirmarContraseña}
                onChange={e => setConfirmarContraseña(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment>
                            <CheckOutlinedIcon />
                        </InputAdornment>
                    )
                }} />

            <Button variant="contained"
                onClick={() => enviar({
                    role: role,
                    username: usuario,
                    lastName: apellido,
                    email: email,
                    phoneNumber: numero,
                    password: password,
                    confirmPassword: confirmarContraseña,
                })}>Registrarse</Button>

            {/* Mostrar mensajes de éxito o error */}
            {mensaje && <Typography variant="h6" color="success" sx={{ marginTop: '16px' }}>{mensaje}</Typography>}
            {error && <Typography variant="h6" color="error" sx={{ marginTop: '16px' }}>{error}</Typography>}

        </Box>
    );
}

export default Registro;
